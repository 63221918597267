import styled from 'styled-components'

// TODO: explore importing Menu from @agnostack/components-atoms
import { Autocomplete, Item, Menu } from '@zendeskgarden/react-dropdowns'

import { SVGIcon } from '@agnostack/components-icons'

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  height: 40px;
  border-radius: 20px;

  ${({ 'data-active': active, theme }) => active && `
    flex-grow: 1;
    margin-left: 30%;
    background: ${theme.colors.light};

    @media (max-width: ${theme.breakpoints.md}) {
      margin-left: 0;
      flex-grow: 0;
    }
  `}
`

export const DropdownWrapper = styled.div`
  flex-grow: 1;
  margin-left: 6px;
`

export const StyledSVGIcon = styled(SVGIcon).attrs(() => ({
  size: 'regular',
}))`
  cursor: pointer;
  border-radius: inherit;
  padding: 12px;
  transition: background .4s ease;

  ${({ 'data-active': active, theme }) => (active ? `
    color: ${theme.colors.dark};
    fill: ${theme.colors.dark};
    stroke: ${theme.colors.dark};
  ` : `
    @media (min-width: ${theme.breakpoints.md}) {
      &:hover {
        background-color: ${theme.colors.toAlpha(theme.colors.light)};
      }
    }
  `)}
`

export const StyledAutocomplete = styled(Autocomplete).attrs(() => ({
  isBare: true,
}))`
  padding-left: 16px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.md}) {
      width: 45vw;
    }
  `}
`

export const SearchMenu = styled(Menu)`
  margin-top: 10px;
`

export const SearchItem = styled(Item)`
  padding: 8px 16px;
  ${({ highlight, theme }) => highlight && `
    background: ${theme.palette.lemon['100']};

    &:hover {
      background: ${theme.palette.lemon['200']};
    }
  `}
`
