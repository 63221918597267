import styled from 'styled-components'
// TODO: explore importing Menu from @agnostack/components-atoms
import { Menu, Item } from '@zendeskgarden/react-dropdowns'

import { stringNotEmpty } from '@agnostack/lib-core'

export const StyledMenu = styled(Menu)`
  && {
    padding-inline-start: unset;
  }
`

export const SelectedItem = styled.span.attrs(({ item: { label } = {} }) => ({
  children: label,
}))`
  ${({ theme, item: { value } = {} }) => !stringNotEmpty(value) && `
    && {
      color: ${theme.colors.disabled};
    }
  `}
`

export const StyledItem = styled(Item)`
  ${({ theme, value: { value } = {} }) => !stringNotEmpty(value) && `
    &, & > * {
      background-color: ${theme.colors.tertiary};
      color: ${theme.colors.disabled};
    }
  `}
`
