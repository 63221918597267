/* eslint-disable no-use-before-define, max-classes-per-file */
import {
  ensureArray,
  ensureObject,
  ensureNumeric,
  isNumericOnly,
  stringNotEmpty,
  stringEmpty,
  arrayEmpty,
  isType,
} from '@agnostack/lib-core'

// NOTE: this cleans up all formats of error messages
const aggregateErrorMessages = (errors) => {
  try {
    return ensureArray(errors).reduce((_messages, value) => {
      const { field, message: _message = '', extensions, ...nestedErrors } = isType(value, 'string')
        ? { message: value }
        : ensureObject(value)

      const messages = Object.values(nestedErrors)
        .filter((nestedError) => isType(nestedError, 'string'))

      if (stringEmpty(_message) && arrayEmpty(messages)) {
        return _messages
      }

      const fields = ensureArray(field).filter(stringNotEmpty)

      const message = arrayEmpty(fields)
        ? _message
        : [
          _message,
          `Fields: ${fields.join(', ')}`
        ].filter(stringNotEmpty).join('. ')

      return [
        ..._messages,
        ...messages,
        ...stringNotEmpty(message) ? [message] : []
      ]
    }, [])
  } catch (ignore) {
    console.warn('Ignoring error aggregating error messages')
    return undefined
  }
}

export const getError = (response) => {
  // NOTE this is not using ensureObject(response) b/c response is an error class
  const { value, error: responseError, responseJSON } = response || {}
  const { error: cacheError } = value || {}
  const { error: JSONError } = responseJSON || {}
  const error = cacheError || responseError || JSONError
  const { data, statusCode: _statusCode, status: _status, code: _code } = error || response || {}
  const { statusCode, status } = ensureObject(data)

  const errorCode = [_code, _statusCode, _status, statusCode, status]
    .find((_errorCode) => isNumericOnly(_errorCode))

  return {
    // eslint-disable-next-line eqeqeq
    code: (errorCode != undefined) ? ensureNumeric(errorCode) : errorCode,
    error,
  }
}

export const getErrorCode = (response) => (
  getError(response).code
)

export const handleHttpError = (response, message = 'HTTP error occurred') => {
  const { code, error } = getError(response)

  if (error instanceof Error) {
    throw error
  }

  if (ensureNumeric(code) > 299) {
    // NOTE: response here is often before calling .json()/.text()
    throw new HandleableError(message, response, code)
  }

  return response
}

export const handleProxyError = (response, message = 'Proxy error occurred') => (
  handleHttpError(response, message)
)

export class HttpError extends Error {
  constructor(message, code, data) {
    super(message)
    this.data = data
    this.code = ensureNumeric(code)
    this.name = 'HttpError'
    Object.setPrototypeOf(this, HttpError.prototype)
  }
}

export class PermissionsError extends HttpError {
  constructor(message, code, data) {
    super(message, code, data)
    this.name = 'PermissionsError'
    Object.setPrototypeOf(this, PermissionsError.prototype)
  }
}

export class DependencyError extends Error {
  constructor(message) {
    super(message)
    this.name = 'DependencyError'
    Object.setPrototypeOf(this, DependencyError.prototype)
  }
}

export class HandleableError extends Error {
  constructor(message, data, code, errorType) {
    super(message)
    this.data = data
    this.errorType = errorType
    this.code = ensureNumeric(code)
    this.name = 'HandleableError'
    Object.setPrototypeOf(this, HandleableError.prototype)
  }
}

export class RetryError extends HandleableError {
  constructor(message, code, data) {
    super(message)
    this.data = data
    this.code = ensureNumeric(code)
    this.name = 'RetryError'
    Object.setPrototypeOf(this, RetryError.prototype)
  }
}

export class AggregateErrors extends Error {
  constructor(message, data, responses) {
    const _data = ensureArray(data)
    super(message)
    this.responses = responses
    this.data = _data
    // eslint-disable-next-line eqeqeq
    this.errors = _data.filter(({ error }) => error != undefined)
    this.name = 'AggregateErrors'
    Object.setPrototypeOf(this, AggregateErrors.prototype)
  }
}

export class AggregateHandleableError extends Error {
  constructor(message, data, code, errorType) {
    const { response, messages: _messages } = ensureObject(data)
    const messages = [...new Set(ensureArray(_messages))]
    super(message || messages[0])
    this.errorType = errorType
    this.response = response
    this.messages = messages
    this.code = ensureNumeric(code)
    this.name = 'AggregateHandleableError'
    Object.setPrototypeOf(this, AggregateHandleableError.prototype)
  }
}

export class QueryErrors extends AggregateHandleableError {
  constructor(message, response, code, errorType) {
    const { errors: _errors, data } = ensureObject(response)

    const responseErrors = ensureArray(_errors)
    const userErrors = ensureArray(Object.values(ensureObject(data))?.[0]?.userErrors)

    const errors = [
      ...responseErrors,
      ...userErrors
    ]
    const messages = aggregateErrorMessages(errors)

    super(message, {
      messages,
      ...data,
    }, code, errorType)

    this.errors = errors
    this.userErrors = userErrors
    this.responseErrors = responseErrors
    this.name = 'QueryErrors'
    Object.setPrototypeOf(this, QueryErrors.prototype)
  }
}

export class MetaError extends Error {
  constructor(error, meta) {
    const { message } = ensureObject(error)
    super(message)
    this.error = error
    this.meta = meta
    this.name = 'MetaError'
    Object.setPrototypeOf(this, MetaError.prototype)
  }
}
