import React, { useMemo } from 'react'
import { navigate } from 'gatsby'

import { Anchor } from '../../../styles/core'

const LinkAnchor = ({ isExternal: _isExternal, href, target, children = href, onClick, ...renderProps }) => {
  const { isLocal, isAnchor, Component } = useMemo(() => {
    const _isAnchor = href?.startsWith('#')
    const _isMailto = (!_isAnchor && href?.startsWith('mailto'))
    const _isLocal = (!_isMailto && (_isAnchor || href?.startsWith('/') || href?.startsWith('/#')))

    return {
      isLocal: _isLocal,
      isAnchor: _isAnchor,
      Component: (_isAnchor || _isMailto) ? 'a' : Anchor,
    }
  }, [href])

  const isExternal = useMemo(() => (
    _isExternal ?? (!isLocal || ['_blank', '_new'].includes(target))
  ), [_isExternal, isLocal, target])

  const anchorProps = useMemo(() => ({
    ...isExternal && {
      target: target || '_blank',
      rel: 'noopener noreferrer',
      isExternal,
    },
    ...href ? {
      ...isLocal ? {
        'data-href': href,
        onClick: () => {
          onClick?.(href)
          navigate(href)
        },
      } : {
        href,
      },
    } : {
      onClick,
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [isAnchor, isExternal, isLocal, href, target])

  return (
    <Component
      data-type="LinkAnchor"
      {...anchorProps}
      {...renderProps}
    >
      {children}
    </Component>
  )
}

export default LinkAnchor
