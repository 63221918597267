import React, { useEffect, useContext, useState, useMemo, useRef } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'

// TODO: explore importing Dropdown from @agnostack/components-atoms
import { Item, Field, Dropdown } from '@zendeskgarden/react-dropdowns'

import { delay } from '@agnostack/lib-utils-js'
import { stringNotEmpty, arrayNotEmpty, lowercase } from '@agnostack/lib-core'
import { SearchIcon } from '@agnostack/components-icons'

import { GlobalDispatch, GlobalState, GLOBAL_ACTIONS } from '../../../util'
import {
  DropdownWrapper,
  StyledSVGIcon,
  StyledAutocomplete,
  SearchWrapper,
  SearchItem,
  SearchMenu,
} from './Search.style'

const Search = () => {
  const { showSearch } = useContext(GlobalState)
  const dispatch = useContext(GlobalDispatch)
  const inputRef = useRef()

  const {
    localSearchPages: {
      index,
      store,
    } = {},
  } = useStaticQuery(
    graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `
  )

  const [searchQuery, setSearchQuery] = useState()
  const _results = useFlexSearch(searchQuery, index, store)

  const isPrioritized = (term) => (
    stringNotEmpty(term) && lowercase(term).includes(lowercase(searchQuery))
  )

  const { prioritizedResults, results } = useMemo(() => {
    if (!searchQuery) {
      return {
        results: _results,
        prioritizedResults: [],
      }
    }

    return _results?.reduce((previousResults, result) => {
      const { search } = result
      if (!isPrioritized(search)) {
        return {
          ...previousResults,
          results: [
            ...previousResults.results,
            result
          ],
        }
      }

      return {
        ...previousResults,
        prioritizedResults: [
          ...previousResults.prioritizedResults,
          result
        ],
      }
    }, { prioritizedResults: [], results: [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_results, searchQuery])

  const setShowSearch = (value) => {
    dispatch({
      type: GLOBAL_ACTIONS.SET,
      payload: { showSearch: value },
    })
  }

  const handleSearchToggle = () => {
    setShowSearch(!showSearch)
  }

  const handleSelect = (path) => {
    setShowSearch(false)
    navigate(path)
  }

  useEffect(() => {
    const focus = async () => {
      await delay(20)
      if (inputRef && inputRef.current) {
        inputRef.current.addEventListener('blur', () => {
          setShowSearch(false)
        }, { once: true })

        if (showSearch) {
          inputRef.current.click()
        }
      }
    }

    focus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, showSearch])

  return (
    <SearchWrapper data-active={showSearch}>
      {showSearch && (
        <DropdownWrapper>
          <Dropdown
            inputValue={searchQuery}
            onSelect={handleSelect}
            onInputValueChange={setSearchQuery}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
          >
            <Field>
              <StyledAutocomplete inputRef={inputRef}>
                {searchQuery}
              </StyledAutocomplete>
              {stringNotEmpty(searchQuery) && (
                <SearchMenu>
                  {arrayNotEmpty(prioritizedResults) || arrayNotEmpty(results) ? (
                    <>
                      {prioritizedResults.map(({ id, title, subtitle, path }) => (
                        <SearchItem key={id} value={path} highlight>
                          {[title, subtitle].filter(stringNotEmpty).join(' ')}
                        </SearchItem>
                      ))}
                      {results.map(({ id, title, subtitle, path }) => (
                        <SearchItem key={id} value={path}>
                          {[title, subtitle].filter(stringNotEmpty).join(' ')}
                        </SearchItem>
                      ))}
                    </>
                  ) : (
                    <Item disabled>No matches found</Item>
                  )}
                </SearchMenu>
              )}
            </Field>
          </Dropdown>
        </DropdownWrapper>
      )}
      <StyledSVGIcon
        data-active={showSearch}
        onMouseDown={handleSearchToggle}
      >
        <SearchIcon />
      </StyledSVGIcon>
    </SearchWrapper>
  )
}

export default Search
