import { ensureArray, stringNotEmpty, isTypeEnhanced } from '@agnostack/lib-core'

import { EXTERNAL_EVENT_PREFIX } from './events'

export const triggerCloseModal = (modalClient) => (
  modalClient?.invoke?.('destroy')
)

export const triggerEvent = (client, event, data) => {
  if (event) {
    return client?.trigger?.(event, data)
  }
  return undefined
}

export const triggerExternalEvent = (
  client,
  event,
  // eslint-disable-next-line default-param-last
  sender = EXTERNAL_EVENT_PREFIX,
  data
) => {
  if (event) {
    const eventName = `${EXTERNAL_EVENT_PREFIX}${event}`
    return client?.trigger?.(eventName, { body: data, sender })
  }
  return undefined
}

export const triggerNotification = (client, message, { type, duration, ...data } = {}) => {
  if (stringNotEmpty(message)) {
    const convertedType = ['notice', 'alert', 'error'].includes(type)
      ? type
      : 'notice'

    // eslint-disable-next-line eqeqeq
    const notifyArg = (duration != undefined) ? duration : {
      sticky: type === 'error',
      ...data,
    }

    // TODO: call crashlytics/logrocket/sentri.io (firebase analytics w/ app_exception) if type='error'
    return client?.invoke?.('notify', message, convertedType, notifyArg)
  }
  return undefined
}

export const triggerErrorMessage = (client, message, data = {}) => (
  triggerNotification(client, message, { type: 'error', ...data })
)

export const triggerCatchableErrors = (client, errors) => {
  const caughtErrors = ensureArray(errors).filter((error) => isTypeEnhanced(error, 'object') && (error.type === 'error') && error.message)

  if (client && (caughtErrors.length > 0)) {
    const message = caughtErrors.map(({ message: _message }) => _message).join(', ')
    triggerErrorMessage(client, message)
  }
}
