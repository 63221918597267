import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { document } from 'browser-monads-ts'

import { NavigationLink } from '../../../styles/core'
import {
  StyledBurger,
  StyledMenu,
  StyledMobileHeader,
  MenuLogoWrapper,
  MenuBar,
  MenuBarLogoWrapper,
  Copyright,
} from './MobileHeader.style'

const Burger = ({ open }) => (
  <StyledBurger open={open}>
    <div />
    <div />
    <div />
  </StyledBurger>
)

const Menu = ({ open, items, onClick }) => (
  <StyledMenu open={open}>
    <MenuLogoWrapper>
      <Link to="/">
        <img src="/images/agnoStack-icon-inverse.png" alt="agnoStack" />
      </Link>
    </MenuLogoWrapper>
    {items?.map(({ text, link, CTA }, index) => (
      // eslint-disable-next-line no-nested-ternary
      CTA ? (
        <CTA key={`mobile-header-${index}`} />
      ) : text ? (
        <NavigationLink
          key={`mobile-header-${index}`}
          to={link}
          onClick={onClick}
        >
          {text}
        </NavigationLink>
      ) : null
    ))}
    <Copyright>
      {`© ${new Date().getFullYear()} agnoStack, Inc.`}
    </Copyright>
  </StyledMenu>
)

const MobileHeader = ({ items, children }) => {
  const [open, setOpen] = useState(false)
  const [targetLockElement, setTargetLockElement] = useState(null)

  useEffect(() => {
    if (document) {
      setTargetLockElement(document.querySelector('body'))
    }
  }, [])

  useEffect(() => {
    if (open) {
      disableBodyScroll(targetLockElement)
    } else {
      enableBodyScroll(targetLockElement)
    }
  }, [open, targetLockElement])

  return (
    <StyledMobileHeader>
      <Menu
        open={open}
        items={items?.slice(0, -1) ?? []}
        onClick={() => setOpen(false)}
      />
      <MenuBar onClick={() => setOpen(!open)}>
        <Burger open={open} setOpen={setOpen} />
        <MenuBarLogoWrapper>
          {children}
        </MenuBarLogoWrapper>
      </MenuBar>
    </StyledMobileHeader>
  )
}

export default MobileHeader
