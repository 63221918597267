import React from 'react'
import { navigate } from 'gatsby'

import { useAccordion } from '@zendeskgarden/container-accordion'

import { isMajorVersion } from '@agnostack/lib-utils-js'
import {
  ensureObject,
  ensureArray,
  arrayNotEmpty,
  stringNotEmpty,
  compareString,
} from '@agnostack/lib-core'

import {
  AccordionButton,
  AccordionBody,
  AccordionHeading,
  AccordionSubcategory,
  StyledHeader,
  StyledLink,
  SubCategoryHeader,
  CategoryPage,
  Chevron,
} from './Accordion.style'

const Accordion = ({
  paths: _paths,
  category,
  canonicalPath,
  expandable = false,
  collapsible = false,
}) => {
  const paths = ensureObject(_paths)
  const categories = Object.keys(paths)
  const hasMultiple = categories.length > 1
  const accordionIndex = categories.indexOf(category)
  const {
    getHeaderProps,
    getTriggerProps,
    getPanelProps,
    expandedSections,
    disabledSections,
  } = useAccordion({
    expandedSections: [accordionIndex > -1 ? accordionIndex : 0],
    expandable,
    collapsible,
  })

  return ensureArray(Object.entries(paths)).map(
    ([accordionSectionTitle, accordionCategory], sectionIndex) => {
      const disabled = disabledSections.indexOf(sectionIndex) !== -1
      const hidden = expandedSections.indexOf(sectionIndex) === -1
      const { onClick: triggerClick, ...triggerProps } = getTriggerProps({
        index: sectionIndex,
        role: 'button',
        tabIndex: 0,
        disabled,
      })

      const {
        unsortedSubCategories,
        categoryPages,
      } = ensureArray(accordionCategory).reduce((
        {
          unsortedSubCategories: previousUnsortedSubCategories,
          categoryPages: previousPages,
        },
        currentPage
      ) => {
        const {
          frontmatter: {
            parent,
          } = {},
        } = ensureObject(currentPage)
        const { [parent]: previousSubCategoryPages } = ensureObject(previousUnsortedSubCategories)

        return {
          unsortedSubCategories: {
            ...previousUnsortedSubCategories,
            ...parent && {
              [parent]: [
                ...ensureArray(previousSubCategoryPages),
                currentPage
              ],
            },
          },
          categoryPages: parent
            ? previousPages
            : [
              ...previousPages,
              currentPage
            ],
        }
      }, {
        unsortedSubCategories: {},
        categoryPages: [],
      })

      const sortedSubCategories = Object.entries(unsortedSubCategories)
        .sort(([subCategory1], [subCategory2]) => compareString(subCategory1, subCategory2))

      const renderPages = (pages) => (
        ensureArray(pages).map(
          ({ path, frontmatter: { title, version } = {} }, itemIndex) => (
            <CategoryPage
              key={`accordion-section-${sectionIndex}-item-${itemIndex}-${title || version}`}
              {...getPanelProps({
                index: sectionIndex,
                role: null,
                hidden,
              })}
            >
              <StyledLink isSelected={path === canonicalPath} to={path}>
                <AccordionButton isChild={stringNotEmpty(version) && !isMajorVersion(version)}>
                  {title || version}
                </AccordionButton>
              </StyledLink>
            </CategoryPage>
          )
        )
      )

      const renderSubCategories = () => (ensureArray(sortedSubCategories).map(
        ([subcategoryName, subCategoryPages], subCategoryIndex) => (
          <AccordionSubcategory
            key={`accordion-section-${sectionIndex}-parent-${subCategoryIndex}`}
            {...getPanelProps({
              index: sectionIndex,
              role: null,
              hidden,
            })}
          >
            <SubCategoryHeader>
              {subcategoryName}
            </SubCategoryHeader>
            {renderPages(subCategoryPages)}
          </AccordionSubcategory>
        )
      ))

      const defaultPage = arrayNotEmpty(categoryPages)
        ? ensureArray(accordionCategory)[0]
        : ensureArray(ensureArray(sortedSubCategories[0])[1])[0]

      return (
        <div key={`accordion-section-${sectionIndex}`}>
          <StyledHeader
            {...getHeaderProps({ role: null, ariaLevel: null })}
            {...triggerProps}
            onClick={(event) => ((hidden) ? navigate(ensureObject(defaultPage).path) : triggerClick(event))}
          >
            <AccordionHeading hasMultiple={hasMultiple}>
              {hasMultiple && (
                <Chevron rotated={!hidden} />
              )}
              {accordionSectionTitle}
            </AccordionHeading>
          </StyledHeader>
          <AccordionBody hasMultiple={hasMultiple}>
            {renderPages(categoryPages)}
            {renderSubCategories()}
          </AccordionBody>
        </div>
      )
    }
  )
}

export default Accordion
