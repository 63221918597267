/* eslint-disable prefer-destructuring */
import {
  stringNotEmpty,
  PRODUCT_NAMES,
  PLAN_STARTER,
  PLAN_PROFESSIONAL,
} from '@agnostack/lib-core'

const GATSBY_URL_ZENDESK_AGNOSTACK_LISTING = process.env.GATSBY_URL_ZENDESK_AGNOSTACK_LISTING
const GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING = process.env.GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING
const GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING = process.env.GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING
const GATSBY_URL_ZENDESK_MAGENTO2_LISTING = process.env.GATSBY_URL_ZENDESK_MAGENTO2_LISTING
const GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING = process.env.GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING
// const GATSBY_URL_ZENDESK_SHOPIFY_LISTING = process.env.GATSBY_URL_ZENDESK_SHOPIFY_LISTING

// TODO: use I18n (buttons.schedule-demo) from lib-core (and add any missing translations
export const TITLE_LIVE_DEMO_REGISTER = 'Register for our next live demo'
export const TITLE_SCHEDULE_DEMO = 'Schedule A Demo'
export const TITLE_BOOK_MEETING = 'Book A Meeting'
export const TITLE_SUBSCRIBE = 'Subscribe'
export const TITLE_GET_HELP = "We're here to help!"
export const TITLE_SHARE = 'Share via'

export const LISTINGS = {
  agnostack: {
    url: GATSBY_URL_ZENDESK_AGNOSTACK_LISTING,
    title: 'agnoStack',
    name: 'agnoStack Commerce',
  },
  ...stringNotEmpty(GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING) && {
    bigcommerce: {
      url: GATSBY_URL_ZENDESK_BIGCOMMERCE_LISTING,
      title: 'BigCommerce',
      name: 'BigCommerce by agnoStack',
    },
  },
  ...stringNotEmpty(GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING) && {
    commercetools: {
      url: GATSBY_URL_ZENDESK_COMMERCETOOLS_LISTING,
      title: 'commercetools',
      name: 'commercetools by agnoStack',
    },
  },
  ...stringNotEmpty(GATSBY_URL_ZENDESK_MAGENTO2_LISTING) && {
    magento2: {
      url: GATSBY_URL_ZENDESK_MAGENTO2_LISTING,
      title: 'Magento 2',
      name: 'Magento 2 by agnoStack',
    },
  },
  // ...stringNotEmpty(GATSBY_URL_ZENDESK_SHOPIFY_LISTING) && {
  //   shopify: {
  //     url: GATSBY_URL_ZENDESK_SHOPIFY_LISTING,
  //     title: 'Shopify',
  //     name: 'Shopify by agnoStack',
  //   }
  // },
  ...stringNotEmpty(GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING) && {
    shopify_premium: {
      url: GATSBY_URL_ZENDESK_SHOPIFY_PREMIUM_LISTING,
      title: 'Shopify',
      // title: 'Shopify Premium',
      name: 'Shopify Premium for Zendesk',
      exclude: [
        PRODUCT_NAMES[PLAN_STARTER],
        PRODUCT_NAMES[PLAN_PROFESSIONAL]
      ],
      mutate: ({ minimum_agents, ...tierData }) => (
        tierData
      ),
    },
  },
}

export const LISTING_NAMES = Object.keys(LISTINGS)

export const GLOBAL_PARAMS = {
  DISPLAY: 'display',
  LISTING: 'listing',
}

export const GLOBAL_MODES = {
  DEBUG: 'debug',
  PREVIEW: 'preview',
  BARE: 'bare',
}
