import React, { useContext, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import {
  objectNotEmpty,
  ensureObject,
  ensureString,
  DEFAULT_LOCALE,
} from '@agnostack/lib-core'

import { GlobalState } from '../../../util'

const SEO = ({
  title: _title,
  keywords,
  description,
  author,
  canonicalPath,
  imagePath,
  parentPath,
  path,
  meta = [],
  lang = DEFAULT_LOCALE,
  type = 'website',
  ...metaTags
}) => {
  const { modalsState } = useContext(GlobalState)

  const {
    site: {
      siteMetadata: {
        tagline,
        siteUrl,
        title: siteTitle,
        description: siteDescription,
        author: siteAuthor,
        keywords: siteKeywords,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            tagline
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const modalOpen = useMemo(() => (
    Object.values(ensureObject(modalsState)).some(({ visible }) => visible)
  ), [modalsState])

  const metaKeywords = !keywords ? siteKeywords : keywords.concat(', ', siteKeywords)
  const metaDescription = description || siteDescription
  const title = _title || tagline

  const pageMeta = [
    {
      name: 'keywords',
      content: metaKeywords,
    },
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'author',
      content: author || siteAuthor,
    },
    {
      name: 'og:locale',
      content: lang,
    },
    {
      property: 'og:site_name',
      content: siteTitle,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: type,
    },
    {
      name: 'twitter:site',
      content: siteAuthor,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: author || siteAuthor,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    }
  ]
  if (imagePath) {
    const imageURL = ensureString(imagePath).startsWith('http')
      ? imagePath
      : `${siteUrl}${imagePath}`
    pageMeta.push({
      property: 'og:image',
      content: imageURL,
    }, {
      property: 'twitter:image',
      content: imageURL,
    })
  }
  if (canonicalPath || path) {
    pageMeta.push({
      property: 'og:url',
      content: `${siteUrl}${canonicalPath || path}`,
    })
  }
  if (objectNotEmpty(metaTags)) {
    Object.entries(metaTags).forEach(([tagProperty, tagContent]) => {
      pageMeta.push({
        property: tagProperty,
        content: tagContent,
      })
    })
  }

  return (
    <Helmet
      title={title}
      titleTemplate={`%s .: ${siteTitle}`}
      meta={pageMeta.concat(meta)}
      htmlAttributes={{
        lang,
        // NOTE: this is to fix the garden modal component focusjail not unsetting overflow when closed
        class: `modal ${modalOpen ? 'open' : 'closed'}`,
      }}
    >
      {canonicalPath && (
        <link
          rel="canonical"
          href={`${siteUrl}${canonicalPath}`}
        />
      )}
    </Helmet>
  )
}

export default SEO
