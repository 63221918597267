import { isType } from '@agnostack/lib-core'
import { deepEqual } from '@agnostack/lib-utils-js'
import React from 'react'

// https://stackoverflow.com/questions/28784050/react-how-to-compare-current-props-children-with-new-one
// Flattens all child elements into a single list
const flatten = (children, flat = []) => {
  // eslint-disable-next-line no-param-reassign
  flat = [
    ...flat,
    ...React.Children.toArray(children)
  ]

  if (children?.props?.children) {
    return flatten(children.props.children, flat)
  }

  return flat
}

// Strips all circular references and internal fields
const reactComparable = (children) => {
  const flat = flatten(children)

  return flat.map(({
    key,
    // ref,
    type,
    props: {
      children: _children,
      ...props
    },
  }) => {
    const isSimple = isType(_children, 'string') || isType(_children, 'number')
    return {
      key,
      // ref,
      type,
      props,
      ...isSimple && {
        children: _children,
      },
    }
  })
}

export const compareChildren = (prev, next) => (
  deepEqual(
    reactComparable(prev?.children),
    reactComparable(next?.children)
  )
)

export const flattenChildren = (children, previous = null) => {
  if (!children) {
    return previous
  }

  switch (typeof children) {
    case 'string': {
      return `${previous ? `${previous} ` : ''}${children}`
    }

    case 'symbol': {
      return previous
    }

    case 'object': {
      return Object.values(children).reduce((current = previous, child) => {
        if (typeof child === 'string') {
          return `${current} ${child}`
        }

        if ((typeof child === 'object') && Array.isArray(child?.children)) {
          return child.children.reduce((_previous, subChild) => (
            flattenChildren(React.isValidElement(subChild) ? subChild.props?.children : subChild, _previous)
          ), current)
        }

        if (React.isValidElement(child)) {
          return flattenChildren(child.props?.children, current)
        }

        return current
      }, previous || '')
    }

    default:
      return previous
  }
}
