import { useEffect } from 'react'

import { ensureArray } from '@agnostack/lib-core'

export const useDelay = (time = 100, callback = () => {}, deps) => {
  useEffect(() => {
    const timer = setTimeout(() => callback(), time)

    return () => {
      clearTimeout(timer)
    }
  }, [...ensureArray(deps)])
}
